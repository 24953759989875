import { Loading } from 'components/shared'
import {
    Button,
    Card,
    Checkbox,
    DatePicker,
    FormContainer,
    FormItem,
    Input,
    Notification,
    Radio,
    Select,
    toast,
} from 'components/ui'
import { STEPS } from 'components/ui/utils/constant'
import { getErrorMessage } from 'components/ui/utils/errorMessage'
import dayjs from 'dayjs'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    apiGetCompanyInfo,
    apiGetCompanyInfoByRegistationNumber,
    apiUpdateCompanyBasicInfo,
} from 'services/StepService'
import { setIsCompanyProfileCompleted, stepChange } from 'store/step/stepSlice'
import * as Yup from 'yup'

const formikRef = React.createRef()

const taxOptions = [
    { value: 'Loss Making', label: 'Loss Making' },
    { value: 'Profit Making', label: 'Profit Making' },
    { value: 'Breakeven', label: 'Breakeven' },
]

const rndOptions = [
    { value: 'Software Development', label: 'Software Development' },
    { value: 'Engineering (General)', label: 'Engineering (General)' },
    { value: 'Mechanical Engineering', label: 'Mechanical Engineering' },
    { value: 'Electrical Engineering', label: 'Electrical Engineering' },
    { value: 'Chemical Engineering', label: 'Chemical Engineering' },
    { value: 'Manufacturing Processes', label: 'Manufacturing Processes' },
    { value: 'Oil & Gas', label: 'Oil & Gas' },
    { value: 'FMCG', label: 'FMCG (Fast Moving Consumer Goods)' },
    { value: 'Advanced Materials', label: 'Advanced Materials' },
    { value: 'Construction', label: 'Construction' },
    { value: 'Architecture and Design', label: 'Architecture and Design' },
    { value: 'Biotechnology and Life Sciences', label: 'Biotechnology and Life Sciences' },
    { value: 'Nanotechnology', label: 'Nanotechnology' },
    { value: 'Artificial Intelligence and Machine Learning', label: 'Artificial Intelligence and Machine Learning' },
    { value: 'Robotics and Automation', label: 'Robotics and Automation' },
    { value: 'Environmental Science and Sustainability', label: 'Environmental Science and Sustainability' },
    { value: 'Energy Systems and Renewable Energy', label: 'Energy Systems and Renewable Energy' },
    { value: 'Aerospace and Aviation', label: 'Aerospace and Aviation' },
    { value: 'Telecommunications', label: 'Telecommunications' },
    { value: 'Medical Devices and Healthcare Technology', label: 'Medical Devices and Healthcare Technology' },
    { value: 'Pharmaceutical Research', label: 'Pharmaceutical Research' },
    { value: 'Food Science and Technology', label: 'Food Science and Technology' },
    { value: 'Quantum Computing', label: 'Quantum Computing' },
    { value: 'Cybersecurity', label: 'Cybersecurity' },
    { value: 'Internet of Things (IoT)', label: 'Internet of Things (IoT)' },
    { value: 'Virtual and Augmented Reality', label: 'Virtual and Augmented Reality' },
    { value: 'Data Science and Analytics', label: 'Data Science and Analytics' },
    { value: 'Agricultural Technology', label: 'Agricultural Technology' },
    { value: 'Automotive Technology', label: 'Automotive Technology' },
    { value: 'Blockchain and Distributed Ledger Technology', label: 'Blockchain and Distributed Ledger Technology' },
    { value: 'Other', label: 'Other (please specify)' },
]
const validationSchema = Yup.object().shape({
    company_registration_number: Yup.string()
        .required('Please enter your company registration number')
        .nullable(),
    company_name: Yup.string()
        .required('Please enter your company name')
        .nullable(),
    address_line_1: Yup.string()
        .when('trading_address_same', {
            is: false,
            then: Yup.string().required('Please enter your address line 1'),
        })
        .nullable(),
    address_line_2: Yup.string().nullable(),
    locality: Yup.string()
        .when('trading_address_same', {
            is: false,
            then: Yup.string().required('Please enter your city'),
        })
        .nullable(),
    postal_code: Yup.string()
        .when('trading_address_same', {
            is: false,
            then: Yup.string().required('Please enter your postal code'),
        })
        .nullable(),
    country: Yup.string()
        .when('trading_address_same', {
            is: false,
            then: Yup.string().required('Please enter your country'),
        })
        .nullable(),
    website: Yup.string()
        .required('Please enter your website')
        .matches(
            /^((https?:\/\/)?(www\.)?)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
            'Please enter a valid website URL'
        )
        .nullable(),
    sector: Yup.string().required('Please enter your sector').nullable(),
    tax_profile: Yup.string()
        .required('Please enter your tax profile')
        .nullable(),
    pending_patents: Yup.bool()
        .required('Please select if you have pending/granted patents')
        .nullable(),
    patent_date: Yup.date()
        .when('pending_patents', {
            is: true,
            then: Yup.date().required('Please enter your patent date'),
        })
        .nullable(),

    r_and_d_activities: Yup.string()
        .required('Please select your R&D activities')
        .nullable(),

    other_r_and_d_activities: Yup.string()
        .when('r_and_d_activities', {
            is: 'Other',
            then: Yup.string().required('Please specify your R&D activities'),
        })
        .nullable(),
    accounting_period_changed: Yup.bool()
        .required('Please select if your accounting period has changed in the last 3 years')
        .nullable(),

    new_accounting_period: Yup.date()
        .when('accounting_period_changed', {
            is: true,
            then: Yup.date().required('Please enter your new accounting period'),
            otherwise: Yup.date().nullable(),
        })
        .nullable(),
    accounting_period_length: Yup.number()
        .required('Please enter your accounting period length')
        .min(1, 'Accounting period length must be at least 1 month')
        .nullable(),
    trading_address_same: Yup.bool().nullable(),
})

const initFormValue = {
    company_registration_number: '',
    company_name: '',
    address_line_1: '',
    address_line_2: '',
    locality: '',
    postal_code: '',
    country: 'United Kingdom', // Default to United Kingdom
    website: '',
    sector: '',
    tax_profile: '',
    pending_patents: false,
    patent_date: '',
    r_and_d_activities: '',
    accounting_period_changed: false,
    new_accounting_period: '',
    accounting_period_length: 0,
    trading_address_same: true,
}

export default function Step2() {
    const dispatch = useDispatch()
    const [companyProfile, setCompanyProfile] = useState(initFormValue)
    const [loading, setLoading] = useState(false)
    const [isSubmitRegistration, setIsSubmitRegistration] = useState(false)
    const [disableForm, setDisableForm] = useState(true)
    const navigate = useNavigate()
    const [companyInfo, setCompanyInfo] = useState()

    useEffect(() => {
        dispatch(stepChange(STEPS.COMPANY_INFO))
        fetchCompanyBasicInfo()
    }, [])

    const fetchCompanyBasicInfo = async () => {
        try {
            setLoading(true)
            const resp = await apiGetCompanyInfo()
            if (resp.status === 200) {
                const is_completed = resp?.data?.data?.is_completed
                dispatch(setIsCompanyProfileCompleted(is_completed))
                setDisableForm(!is_completed)
                const data = resp?.data?.data?.company_profile
                if (data.registered_address) {
                    setCompanyInfo({
                        registered_address: data.registered_address,
                    })
                }
                pathFormValue(data)
            }
            setLoading(false)
        } catch (error) {
            toast.push(
                <Notification title={'Message'} type={'danger'}>
                    {getErrorMessage(error)}
                </Notification>
            )
            setLoading(false)
        }
    }

    // Helper function to format dates
    const formatDate = (date) => date ? dayjs(date).format('YYYY-MM-DD') : null;

    // Helper function to construct the trading address
    const constructTradingAddress = (values) => {
        const { address_line_1, address_line_2, locality, postal_code, country } = values;
        return {
            address_line_1,
            address_line_2: address_line_2 || '',  // Provide an empty string if address_line_2 is not provided
            locality,
            postal_code,
            country,
        };
    }

    const onNext = async (values, setSubmitting) => {
        try {
            setSubmitting(true);
    
            const {
                new_accounting_period,
                r_and_d_activities,
                other_r_and_d_activities,
                trading_address_same,
                pending_patents,  // Ensure this is included in the payload
                patent_date,
                ...rest
            } = values;
    
            const payload = {
                ...rest,
                pending_patents,  // Include pending_patents in the payload
                new_accounting_period: formatDate(new_accounting_period),
                patent_date: pending_patents ? formatDate(patent_date) : null,
                r_and_d_activities: r_and_d_activities === 'Other' ? other_r_and_d_activities : r_and_d_activities,
                registered_address: {
                    address_line_1: companyInfo?.registered_address?.address_line_1,
                    address_line_2: companyInfo?.registered_address?.address_line_2,
                    locality: companyInfo?.registered_address?.locality,
                    postal_code: companyInfo?.registered_address?.postal_code,
                    country: companyInfo?.registered_address?.country,
                },
                trading_address_same,
                trading_address: trading_address_same ? null : constructTradingAddress(values),
            };
    
            delete payload.other_r_and_d_activities;
    
            const response = await apiUpdateCompanyBasicInfo(payload);
    
            if (response.status === 200) {
                const is_completed = response?.data?.data?.is_completed;
                dispatch(setIsCompanyProfileCompleted(is_completed));
                navigate('/home/background-details');
            }
        } catch (error) {
            toast.push(
                <Notification title="Error" type="danger">
                    {getErrorMessage(error)}
                </Notification>
            );
        } finally {
            setSubmitting(false);
        }
    }

    const handleBlur = async (event) => {
        if (event.target.value.trim() === '') return
        try {
            setIsSubmitRegistration(true)
            const resp = await apiGetCompanyInfoByRegistationNumber({
                company_registration_number: event.target.value,
            })
            if (resp.status === 200) {
                setDisableForm(false)
                const currentForm = {
                    ...resp.data.data,
                    trading_address_same: formikRef?.current?.values?.trading_address_same,
                    sector: formikRef?.current?.values?.sector || '', // Keep existing sector value or set empty
                }
                pathFormValue(currentForm)
                setCompanyInfo(resp.data.data)
            } else {
                setDisableForm(true)
                setCompanyInfo(null)
                setCompanyProfile({})
                setEmptyFieldValue()
                toast.push(
                    <Notification title={'Message'} type={'danger'}>
                        {getErrorMessage(resp)}
                    </Notification>
                )
            }
            setIsSubmitRegistration(false)
        } catch (error) {
            setCompanyProfile({})
            setCompanyInfo(null)
            setEmptyFieldValue()
            setDisableForm(true)
            setIsSubmitRegistration(false)
            toast.push(
                <Notification title={'Message'} type={'danger'}>
                    {getErrorMessage(error)}
                </Notification>
            )
        }
    }

    const setEmptyFieldValue = () => {
        const resetForm = { ...initFormValue }
        resetForm.company_registration_number =
            formikRef.current.values?.company_registration_number
        setCompanyProfile(resetForm)
    }

    const predefinedRndOptions = rndOptions.map(option => option.value);

    const pathFormValue = (data) => {
        const init = {
            ...data,
        };

        // Check if r_and_d_activities is one of the predefined options
        if (!predefinedRndOptions.includes(init.r_and_d_activities)) {
            // If not, set it to "Other" and move the value to other_r_and_d_activities
            init.other_r_and_d_activities = init.r_and_d_activities;
            init.r_and_d_activities = 'Other';
        } else {
            // Otherwise, clear other_r_and_d_activities
            init.other_r_and_d_activities = '';
        }

        // Handling the trading address logic
        if (data.trading_address) {
            init.country = data.trading_address?.country || 'United Kingdom';
            init.locality = data.trading_address?.locality;
            init.postal_code = data.trading_address?.postal_code;
            init.address_line_1 = data.trading_address?.address_line_1;
            init.address_line_2 = data.trading_address?.address_line_2;
        }

        delete init.registered_address;
        const dateFields = ['patent_date', 'new_accounting_period'];
        dateFields.forEach((field) => {
            if (init[field]) {
                init[field] = new Date(init[field]);
            }
        });

        setCompanyProfile(init);
    };

    return (
        <div className="w-full flex flex-col px-4 py-8 items-center">
            {loading ? (
                <Loading loading={loading} />
            ) : (
                <Formik
                    enableReinitialize
                    validationSchema={validationSchema}
                    innerRef={formikRef}
                    initialValues={companyProfile}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true)
                        onNext(values, setSubmitting)
                    }}
                >
                    {({ values, touched, errors, isSubmitting }) => (
                        <div className="w-full">
                            <Form>
                                <FormContainer>
                                    <Loading
                                        loading={isSubmitRegistration}
                                        type="cover"
                                    >
                                        <div
                                            style={{ height: '67vh' }}
                                            className="bg-slate-50 p-7 rounded-md overflow-auto"
                                        >
                                            <FormItem
                                                label="Company Registration Number"
                                                invalid={
                                                    errors.company_registration_number &&
                                                    touched.company_registration_number
                                                }
                                                errorMessage={
                                                    errors.company_registration_number
                                                }
                                            >
                                                <Field
                                                    autoComplete="off"
                                                    name="company_registration_number"
                                                    placeholder="Company Registration Number"
                                                    component={Input}
                                                    onBlur={handleBlur}
                                                />
                                            </FormItem>
                                            <div className="flex flex-row gap-4">
                                                <FormItem
                                                    className="flex-1"
                                                    label="Company Name"
                                                    invalid={
                                                        errors.company_name &&
                                                        touched.company_name
                                                    }
                                                    errorMessage={
                                                        errors.company_name
                                                    }
                                                >
                                                    <Field
                                                        disabled={true}
                                                        autoComplete="off"
                                                        name="company_name"
                                                        placeholder="Company Name"
                                                        component={Input}
                                                    />
                                                </FormItem>
                                                <FormItem
                                                    className="flex-1"
                                                    label="Website"
                                                    invalid={
                                                        errors.website &&
                                                        touched.website
                                                    }
                                                    errorMessage={
                                                        errors.website
                                                    }
                                                >
                                                    <Field
                                                        disabled={disableForm}
                                                        name="website"
                                                        placeholder="Website"
                                                        component={Input}
                                                    />
                                                </FormItem>
                                            </div>

                                            {companyInfo && (
                                                <Card className="mb-4">
                                                    <h5>Company address</h5>
                                                    <p>
                                                        {
                                                            companyInfo
                                                                ?.registered_address
                                                                ?.address_line_1
                                                        }
                                                    </p>
                                                    <p>
                                                        {
                                                            companyInfo
                                                                ?.registered_address
                                                                ?.address_line_2
                                                        }
                                                    </p>
                                                    <p>
                                                        {
                                                            companyInfo
                                                                ?.registered_address
                                                                ?.locality
                                                        }
                                                    </p>
                                                    <p>
                                                        {
                                                            companyInfo
                                                                ?.registered_address
                                                                ?.postal_code
                                                        }
                                                    </p>
                                                    <p>
                                                        {
                                                            companyInfo
                                                                ?.registered_address
                                                                ?.country
                                                        }
                                                    </p>
                                                </Card>
                                            )}
                                            {companyInfo && (
                                                <FormItem>
                                                    <Field
                                                        name="trading_address_same"
                                                        component={Checkbox}
                                                    >
                                                        Is your trading address
                                                        the same as your
                                                        registered address
                                                    </Field>
                                                </FormItem>
                                            )}
                                            {!values?.trading_address_same && (
                                                <div>
                                                    <div className="flex flex-row gap-4">
                                                        <FormItem
                                                            className="flex-1"
                                                            label="Address 1"
                                                            invalid={
                                                                errors.address_line_1 &&
                                                                touched.address_line_1
                                                            }
                                                            errorMessage={
                                                                errors.address_line_1
                                                            }
                                                        >
                                                            <Field
                                                                disabled={
                                                                    disableForm
                                                                }
                                                                name="address_line_1"
                                                                placeholder="Address 1"
                                                                component={
                                                                    Input
                                                                }
                                                            />
                                                        </FormItem>
                                                        <FormItem
                                                            className="flex-1"
                                                            label="Address 2"
                                                            invalid={
                                                                errors.address_line_2 &&
                                                                touched.address_line_2
                                                            }
                                                            errorMessage={
                                                                errors.address_line_2
                                                            }
                                                        >
                                                            <Field
                                                                disabled={
                                                                    disableForm
                                                                }
                                                                name="address_line_2"
                                                                placeholder="Address 2"
                                                                component={
                                                                    Input
                                                                }
                                                            />
                                                        </FormItem>
                                                    </div>

                                                    <div className="flex flex-row gap-4">
                                                        <FormItem
                                                            className="flex-1"
                                                            label="City"
                                                            invalid={
                                                                errors.locality &&
                                                                touched.locality
                                                            }
                                                            errorMessage={
                                                                errors.locality
                                                            }
                                                        >
                                                            <Field
                                                                disabled={
                                                                    disableForm
                                                                }
                                                                name="locality"
                                                                placeholder="City"
                                                                component={
                                                                    Input
                                                                }
                                                            />
                                                        </FormItem>
                                                        <FormItem
                                                            className="flex-1"
                                                            label="Postal Code"
                                                            invalid={
                                                                errors.postal_code &&
                                                                touched.postal_code
                                                            }
                                                            errorMessage={
                                                                errors.postal_code
                                                            }
                                                        >
                                                            <Field
                                                                disabled={
                                                                    disableForm
                                                                }
                                                                name="postal_code"
                                                                placeholder="Postal Code"
                                                                component={
                                                                    Input
                                                                }
                                                            />
                                                        </FormItem>
                                                        <FormItem
                                                            className="flex-1"
                                                            label="Country"
                                                            invalid={
                                                                errors.country &&
                                                                touched.country
                                                            }
                                                            errorMessage={
                                                                errors.country
                                                            }
                                                        >
                                                            <Field
                                                                disabled={
                                                                    disableForm
                                                                }
                                                                name="country"
                                                                placeholder="Country"
                                                                component={
                                                                    Input
                                                                }
                                                            />
                                                        </FormItem>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="flex flex-row gap-4">
                                                <FormItem
                                                    className="flex-1"
                                                    label="Sector"
                                                    invalid={
                                                        errors.sector &&
                                                        touched.sector
                                                    }
                                                    errorMessage={errors.sector}
                                                >
                                                    <Field
                                                        disabled={disableForm}
                                                        name="sector"
                                                        placeholder="Sector"
                                                        component={Input}
                                                    />
                                                </FormItem>
                                            </div>

                                            <div className="flex flex-row gap-4">
                                                <FormItem
                                                    className="flex-1"
                                                    label="Changed Accounting Period"
                                                    invalid={
                                                        errors.accounting_period_changed &&
                                                        touched.accounting_period_changed
                                                    }
                                                    errorMessage={
                                                        errors.accounting_period_changed
                                                    }
                                                >
                                                    <Field name="accounting_period_changed">
                                                        {({ field, form }) => (
                                                            <Radio.Group
                                                                disabled={disableForm}
                                                                value={values?.accounting_period_changed}
                                                                onChange={(val) =>
                                                                    form.setFieldValue(
                                                                        field.name,
                                                                        val
                                                                    )
                                                                }
                                                            >
                                                                <Radio value={true}>
                                                                    Yes
                                                                </Radio>
                                                                <Radio value={false}>
                                                                    No
                                                                </Radio>
                                                            </Radio.Group>
                                                        )}
                                                    </Field>
                                                </FormItem>

                                                {values.accounting_period_changed && (
                                                    <FormItem
                                                        className="flex-1"
                                                        label="New Accounting Period"
                                                        invalid={
                                                            errors.new_accounting_period &&
                                                            touched.new_accounting_period
                                                        }
                                                        errorMessage={errors.new_accounting_period}
                                                    >
                                                        <Field
                                                            name="new_accounting_period"
                                                        >
                                                            {({ field, form }) => (
                                                                <DatePicker
                                                                    inputFormat="YYYY-MM-DD"
                                                                    field={field}
                                                                    form={form}
                                                                    value={field.value}
                                                                    onChange={(date) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            dayjs(date).format('YYYY-MM-DD')
                                                                        )
                                                                    }
                                                                    disabled={disableForm}
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormItem>
                                                )}

                                                <FormItem
                                                    className="flex-1"
                                                    label="Accounting Period Length (months)"
                                                    invalid={
                                                        errors.accounting_period_length &&
                                                        touched.accounting_period_length
                                                    }
                                                    errorMessage={
                                                        errors.accounting_period_length
                                                    }
                                                >
                                                    <Field
                                                        type="number"
                                                        min="1"
                                                        disabled={disableForm}
                                                        name="accounting_period_length"
                                                        placeholder="Enter number of months"
                                                        component={Input}
                                                    />
                                                </FormItem>
                                            </div>
                                            <div className="flex flex-row gap-4">
                                                <FormItem
                                                    className="flex-1"
                                                    label="Tax Profile"
                                                    invalid={
                                                        errors.tax_profile &&
                                                        touched.tax_profile
                                                    }
                                                    errorMessage={
                                                        errors?.tax_profile
                                                    }
                                                >
                                                    <Field
                                                        disabled={disableForm}
                                                        name="tax_profile"
                                                    >
                                                        {({ field, form }) => (
                                                            <Select
                                                                isDisabled={
                                                                    disableForm
                                                                }
                                                                field={field}
                                                                form={form}
                                                                options={
                                                                    taxOptions
                                                                }
                                                                value={taxOptions.filter(
                                                                    (option) =>
                                                                        option.value ===
                                                                        values?.tax_profile
                                                                )}
                                                                onChange={(
                                                                    option
                                                                ) =>
                                                                    form.setFieldValue(
                                                                        field.name,
                                                                        option?.value
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                </FormItem>
                                                <FormItem
                                                    className="flex-1"
                                                    label="Nature of R&D Activities"
                                                    invalid={
                                                        errors.r_and_d_activities && touched.r_and_d_activities
                                                    }
                                                    errorMessage={errors.r_and_d_activities}
                                                >
                                                    <Field name="r_and_d_activities">
                                                        {({ field, form }) => (
                                                            <Select
                                                                isDisabled={disableForm}
                                                                field={field}
                                                                form={form}
                                                                options={rndOptions}
                                                                value={rndOptions.filter(
                                                                    (option) => option.value === values?.r_and_d_activities
                                                                )}
                                                                onChange={(option) =>
                                                                    form.setFieldValue(field.name, option?.value)
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                </FormItem>

                                                {/* Conditionally render the "Other" text input */}
                                                {values?.r_and_d_activities === 'Other' && (
                                                    <FormItem
                                                        className="flex-1"
                                                        label="Please Specify"
                                                        invalid={
                                                            errors.other_r_and_d_activities &&
                                                            touched.other_r_and_d_activities
                                                        }
                                                        errorMessage={errors.other_r_and_d_activities}
                                                    >
                                                        <Field
                                                            disabled={disableForm}
                                                            name="other_r_and_d_activities"
                                                            placeholder="Specify your R&D activities"
                                                            component={Input}
                                                        />
                                                    </FormItem>
                                                )}
                                            </div>
                                            <div className="flex flex-row gap-4">
                                                <FormItem
                                                    className="flex-1"
                                                    label="Pending/Granted Patents"
                                                    invalid={
                                                        errors.pending_patents &&
                                                        touched.pending_patents
                                                    }
                                                    errorMessage={
                                                        errors.pending_patents
                                                    }
                                                >
                                                    <Field
                                                        disabled={disableForm}
                                                        name="pending_patents"
                                                    >
                                                        {({ field, form }) => (
                                                            <Radio.Group
                                                                disabled={
                                                                    disableForm
                                                                }
                                                                value={
                                                                    values?.pending_patents ??
                                                                    false
                                                                }
                                                                onChange={(
                                                                    val
                                                                ) =>
                                                                    form.setFieldValue(
                                                                        field.name,
                                                                        val
                                                                    )
                                                                }
                                                            >
                                                                <Radio
                                                                    value={true}
                                                                >
                                                                    Yes
                                                                </Radio>
                                                                <Radio
                                                                    value={
                                                                        false
                                                                    }
                                                                >
                                                                    No
                                                                </Radio>
                                                            </Radio.Group>
                                                        )}
                                                    </Field>
                                                </FormItem>
                                                {values?.pending_patents && (
                                                    <FormItem
                                                        className="flex-1"
                                                        label="Patents Date"
                                                        invalid={
                                                            errors.patent_date &&
                                                            touched.patent_date
                                                        }
                                                        errorMessage={
                                                            errors.patent_date
                                                        }
                                                    >
                                                        <Field
                                                            disabled={
                                                                disableForm
                                                            }
                                                            name="patent_date"
                                                        >
                                                            {({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <DatePicker
                                                                    inputFormat="YYYY-MM-DD"
                                                                    field={
                                                                        field
                                                                    }
                                                                    form={form}
                                                                    value={
                                                                        values.patent_date
                                                                    }
                                                                    onChange={(
                                                                        date
                                                                    ) => {
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            date
                                                                        )
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormItem>
                                                )}
                                            </div>
                                        </div>
                                    </Loading>

                                    <div className="mt-7 flex flex-row text-right gap-7">
                                        <Button
                                            id="prev"
                                            type="button"
                                            block
                                            onClick={() => navigate(-1)}
                                        >
                                            {'Previous'}
                                        </Button>
                                        <Button
                                            block
                                            disabled={disableForm}
                                            type="submit"
                                            variant="solid"
                                            loading={isSubmitting}
                                        >
                                            {'Next'}
                                        </Button>
                                    </div>
                                </FormContainer>
                            </Form>
                        </div>
                    )}
                </Formik>
            )}
        </div>
    )
}
